import {MoonIcon, SunIcon} from "@chakra-ui/icons";
import {Box, Flex, IconButton, Show, Text, useColorMode} from "@chakra-ui/react";
import React from "react";
import {useTranslations} from "next-intl";

export function ColorModePicker(
    {withText = true}: {withText?: boolean}
) {
    const {colorMode, toggleColorMode} = useColorMode()
    const t = useTranslations("Home")

    return (
        <Flex w="100%"
              alignItems="center"
              justify="space-between"
              onClick={toggleColorMode}
        >
            { withText &&
                <Text m={0}>{colorMode == 'light' ? t("darkmode") : t("lightmode")}</Text>
            }
            <IconButton
                variant="solid"
                size="sm"
                isRound={true}
                colorScheme="teal"
                icon={colorMode == 'light' ? <MoonIcon/> : <SunIcon/>}
                aria-label={colorMode == 'light' ? 'Dark mode' : 'Light mode'}/>

        </Flex>
    )
}