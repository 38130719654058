import {formatTimeWithOffset} from "./TimeView";

interface Props{
    period: number[]
}

export function TimePeriodView(
    props: Props
) {
    return (
        <>
            {formatTimeWithOffset(props.period[0])}{props.period[0] > 2359 && <sup>+1</sup>} - {formatTimeWithOffset(props.period[1])}{props.period[1] > 2359 && <sup>+1</sup>}
        </>
    )
}