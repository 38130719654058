import {Bar, BarChain, BarFromDB, BarWithDeals} from "../../interfaces/IBar";
import {doc, documentId, DocumentReference, getCountFromServer, getDoc, query, where} from "@firebase/firestore";
import {db} from "../../firebase/config";
import {collection, getDocs} from "firebase/firestore";
import {BarDeal, BarDealFromDB, ChainDeal, ChainDealFromDB} from "../../interfaces/IDeal";

export async function getBarWithDeals(barId: string): Promise<BarWithDeals|undefined> {
    const bar = await getBar(barId)
    if (!bar) return undefined
    return BarWithDeals(bar, await getBarDeals(bar.id))
}

export async function getBarDeals(barId: string): Promise<BarDeal[]> {
    return (await getDocs(query(
        collection(db, "deals"),
        where("bar", "==", doc(db, "bars", barId))
    ))).docs.map(d => BarDealFromDB(d))
}

export async function getBar(barId: string): Promise<Bar|undefined> {
    const barDoc = await getDoc(doc(db, "/bars", barId))
    if (!barDoc.exists()) return undefined
    return BarFromDB(barDoc)
}

export async function barExists(barId: string): Promise<boolean> {
    const barDoc = await getDoc(doc(db, "/bars", barId))
    return barDoc.exists()
}


export async function getChains(ids: string[] = []): Promise<BarChain[]> {
    const col = collection(db, "chains")
    const docu = ids.length == 0 ? (await getDocs(col)).docs
        : (await getDocs(query(col, where(documentId(), "in", ids)))).docs

    return await Promise.all(docu.map(async (value) => {
        const size = await getBarCount(doc(db, "chains", value.id))
        return {
            id: value.id,
            name: value.data()["name"],
            size: size
        }
    }))
}

export async function getChain(id: string): Promise<BarChain|undefined> {
    const chainRef = doc(db, "chains", id)
    const document = (await getDoc(chainRef))

    const data = document.data()

    return data ? {
        id: document.id,
        name: data["name"],
        size: await getBarCount(chainRef)
    } : undefined
}

export async function getBarsOfChain(chain: BarChain): Promise<Bar[]> {
    const chainRef = doc(db, "chains", chain.id)
    const docs = (await getDocs(query(
        collection(db, "bars"),
        where("chain", "==", chainRef)
    ))).docs
    const bars = (docs.map(async (value) => {
        const bar = await BarFromDB(value, chain)
        return bar ? [bar] : []
    }))
    return (await Promise.all(bars)).flat()
}

async function getBarCount(chainRef: DocumentReference): Promise<number> {
    return (await getCountFromServer(query(collection(db, "bars"), where("chain", "==", chainRef)))).data().count
}


export async function getChainDeals(chainId: string): Promise<ChainDeal[]> {
    const deals = (await getDocs(
        query(
            collection(db, "deals"),
            where("chain", "==", doc(db, "chains", chainId))
        ))
    ).docs
    const chain = await getChain(chainId)
    return chain ? deals.map(value => ChainDealFromDB(value, chain)) : []
}