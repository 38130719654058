import {As, Box, ResponsiveValue, Text, TextProps} from "@chakra-ui/react";
import moment from "moment/moment";
import {Property} from "csstype";

export interface TimeViewStyleProps extends TextProps {
    textStyle?: "normal"|"striked"|"greenbold"|undefined,
}

interface Props extends TimeViewStyleProps {
    time: number|undefined,
    fallback?: string
}

export default function TimeView(
    props: Props
) {
    const next = props.time !== undefined && props.time > 2359

    return (
        <Text
            as={getAs(props)}
            color={getColor(props)}>
                {props.time === undefined ? props.fallback : formatTimeWithOffset(props.time)} {next && <sup>+1</sup>}</Text>
    )
}

function getAs(props: TimeViewStyleProps): As | undefined {
    const asFromStyle = {
        "normal": "span" as As,
        "striked": "s" as As,
        "greenbold": "b" as As
    }
    return props.textStyle !== undefined ? asFromStyle[props.textStyle] : props.as
}

function getColor(props: TimeViewStyleProps): ResponsiveValue<Property.Color> | undefined {
    const colors = {
        "normal": undefined,
        "striked": "red",
        "greenbold": "green"
    }
    return props.textStyle !== undefined ? colors[props.textStyle] : props.color
}

function formatTimePeriod(hours: number[]): string {
    return formatTimeWithOffset(hours[0]) + " - " + formatTimeWithOffset(hours[1])
}

export function formatTimeWithOffset(time: number): string {
    return moment((time % 2400).toString().padStart(4, '0'), "Hmm").format("LT")
}