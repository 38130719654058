import {WorkingHoursMap} from "../tools/groupHours";
import {DocumentReference, DocumentSnapshot} from "@firebase/firestore";
import {BarChain, WithId} from "./IBar";

export interface IDeal extends INewDeal, WithId {}

export interface INewDeal {
    days: WorkingHoursMap,
    description: string,
    conditions: string,
}

export interface GenericRawDeal extends RawChainDeal, RawBarDeal {
    data: DocumentSnapshot
}


export interface IChainDeal {
    chain: BarChain
}

export interface IBarDeal {
    barId: string
}

export interface ChainDeal extends IDeal, IChainDeal {}
export interface BarDeal extends IDeal, IBarDeal {}
export interface NewChainDeal extends INewDeal, IChainDeal {}
export interface NewBarDeal extends INewDeal, IBarDeal {}

export interface RawChainDeal extends IDeal {
    chain: DocumentReference | null,
}

export interface RawBarDeal extends IDeal {
    bar: DocumentReference | null
}

export interface WithOriginalId {
    original: DocumentReference|null
}

export interface NewDealSuggestion extends INewDeal, WithOriginalId {}

// Raw - as read from the DB
export interface RawBarDealSuggestion extends RawBarDeal, WithOriginalId {}
export interface RawChainDealSuggestion extends RawChainDeal, WithOriginalId {}

// New - app level but without id
export interface NewBarDealSuggestion extends IBarDeal, NewDealSuggestion {}
export interface NewChainDealSuggestion extends IChainDeal, NewDealSuggestion {}

// App level
export interface BarDealSuggestion extends NewBarDealSuggestion, WithId {}
export interface ChainDealSuggestion extends NewChainDealSuggestion, WithId {}


export function ReadChainDeal(snapshot: DocumentSnapshot): RawChainDeal {
    return {
        ...snapshot.data(),
        id: snapshot.id
    } as RawChainDeal
}


export function ReadBarDeal(snapshot: DocumentSnapshot): RawBarDeal {
    return {
        ...snapshot.data(),
        id: snapshot.id
    } as RawBarDeal
}


export function BarDealFromDB(snapshot: DocumentSnapshot): BarDeal {
    const raw = ReadBarDeal(snapshot)
    return BarDeal(raw)
}

export function BarDeal(raw: RawBarDeal): BarDeal {
    return {
        days: raw.days,
        description: raw.description,
        conditions: raw.conditions,
        barId: raw.bar?.id,
        id: raw.id
    } as BarDeal
}

export function ChainDeal(raw: RawChainDeal, chain: BarChain): ChainDeal {
    return {
        days: raw.days,
        description: raw.description,
        conditions: raw.conditions,
        id: raw.id,
        chain: chain
    }
}

export function ChainDealFromDB(data: DocumentSnapshot, chain: BarChain): ChainDeal {
    return ChainDeal(ReadChainDeal(data), chain)
}

export function ChainDealSuggestionFromDB(data: DocumentSnapshot, chain: BarChain): ChainDealSuggestion {
    const raw = {
        ...data.data(),
        id: data.id
    } as RawChainDealSuggestion
    return {
        days: raw.days,
        description: raw.description,
        conditions: raw.conditions,
        original: raw.original,
        id: raw.id,
        chain: chain
    }
}

export function BarDealSuggestionFromDB(data: DocumentSnapshot): BarDealSuggestion {
    const raw = {
        ...data.data(),
        id: data.id
    } as RawBarDealSuggestion
    return {
        original: raw.original,
        id: raw.id,
        days: raw.days,
        description: raw.description,
        conditions: raw.conditions,
        barId: raw.bar?.id ?? ""
    }
}

export function WriteBarDealSuggestion(barDoc: DocumentReference, deal: BarDealSuggestion): RawBarDealSuggestion {
    return {
        bar: barDoc,
        days: deal.days,
        description: deal.description,
        conditions: deal.conditions,
        original: deal.original
    } as RawBarDealSuggestion
}

export function WriteChainDealSuggestion(chainDoc: DocumentReference, deal: ChainDealSuggestion): RawChainDealSuggestion {
    return {
        chain: chainDoc,
        days: deal.days,
        description: deal.description,
        conditions: deal.conditions,
        original: deal.original
    } as RawChainDealSuggestion
}
