'use client'

import {Box, Button, Card, CardBody, CardHeader, Flex, Heading, HStack, Img, Spacer, Stack} from "@chakra-ui/react";
import styles from "./layout.module.css";
import React, {ReactNode} from "react";
import {Link} from "../../i18n/routing";
import {TopMenu} from "../TopMenu";
import {useTranslations} from "next-intl";
import {ArrowLeftIcon} from "@chakra-ui/icons";
import {BarChain} from "../../interfaces/IBar";

export function HeaderWithBackground(
    {title, bg, fullHeight=false, applyMarginToBody=true, children}: {title: string, bg: string, fullHeight?:boolean, applyMarginToBody?:boolean, children: ReactNode}
) {

    const headerMargin = fullHeight ? undefined : "10px"

    return (
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflowY='auto'
            overflowX='hidden'
            width="100vw"
            borderWidth="0px"
            height={fullHeight ? "100vh" : {base: "60vh", xl: "60vh"}}
            borderRadius="none"
            bgImage={bg}
            bgSize="cover"
            bgPos={{base: "center"}}>


            <Box w="100%" zIndex={1}>
                <Flex zIndex={-10} pos="absolute" height="100%" w="100%" className={styles.theblur}></Flex>
                <CardHeader pos="relative" zIndex={100}>
                    <Stack direction="row" alignItems="center">
                        <HStack spacing="20px" alignItems="center">
                            <Link href={{pathname: '/'}}><Img src="/images/logo.png" h="80px" w="140px"></Img></Link>
                            <Heading size={{base: "sm", md: "md", lg: "lg"}} as="h1" zIndex={2} color="white">
                                {title}
                            </Heading>
                        </HStack>
                        <Spacer/>
                        {/**/}
                        <TopMenu/>
                    </Stack>
                </CardHeader>
                <CardBody m={applyMarginToBody ? headerMargin : undefined} p={0} zIndex={2} pos="relative">
                    {children}
                </CardBody>
            </Box>
        </Card>
    )
}

export interface PathConfig {
    showHome: boolean,
    showChain: BarChain|undefined,
}

export function PageContentCard(
    {children, header}: {children: ReactNode, header: ReactNode}
) {
    const t = useTranslations("bar")
    return (
        <Flex w="100%" justify="center" align="center" direction="column" gap={2}>
            <Box w={{base: "100%", lg: "50%"}}>
                <Link href={{pathname: "/"}}><Button variant="solid" leftIcon={<ArrowLeftIcon/>}>{t("home")}</Button></Link>
            </Box>
            <Card w={{base: "100%", lg: "50%"}} borderRadius="20px">
                <CardHeader>{header}</CardHeader>
                <CardBody>
                    {children}
                </CardBody>
            </Card>
        </Flex>
    )
}

export function PageContentHeading(
    {title}: {title: string}
) {
    return <Heading size="lg">{title}</Heading>
}