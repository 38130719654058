'use client'

import {useLocale, useTranslations} from "next-intl";
import {usePathname, useRouter} from "../i18n/routing";
import {Flex, IconButton, Text} from "@chakra-ui/react";
// import {GB, LV} from "country-flag-icons/react/1x1";
import LV from "country-flag-icons/react/1x1/LV"
import GB from "country-flag-icons/react/1x1/GB"

export default function LanguagePicker(
    {withText = true}: {withText?: boolean}
) {
    const locale = useLocale()
    const router = useRouter()
    const pathname = usePathname();
    function changeLocale(locale: string) {
        // @ts-ignore
        router.replace(pathname,{locale: locale})
    }
    const t = useTranslations("Home")
    const target = locale == 'en' ? 'lv' : 'en'
    return (
        <Flex
            onClick={e => changeLocale(target)}
            w="100%"
            alignItems="center"
            justify="space-between"
        >
            { withText && (<Text m={0}>{t("language")}</Text>) }
            <IconButton
                size="sm"
                borderRadius="50%"
                overflow="hidden"
                // onClick={e => changeLocale(target)}
                icon={target == "lv" ? <LV/> : <GB/>}
                aria-label={target}/>
        </Flex>
    )
}