// Define the type for the working hours map (key is number instead of string)
import moment from "moment";

export type WorkingHoursMap = { [key: number]: number[] };

export interface DayHours {
    start: number|undefined,
    end: number|undefined,
}


// Utility function to check if two arrays are equal
function areHoursEqual(hours1: number[], hours2: number[]): boolean {
    if (hours1.length !== hours2.length) return false;
    for (let i = 0; i < hours1.length; i++) {
        if (hours1[i] !== hours2[i]) return false;
    }
    return true;
}

// Define the structure for the group result
export interface Schedule {
    days: number[];
    hours: number[];
}

function isSequential(data: number[]) {
    for (let i = 1; i < data.length; i++) {
        // check if current value smaller than previous value
        if (data[i] - data[i - 1] > 1) {
            return false;
        }
    }

    return true;
}

export function formatWeekdayList(days: number[]): string {

    function formatSequentialGroups(group: number[]): string {
        if (group.length >= 2) {
            return moment().day(group[0]).format("dddd") + (group.length == 2 ? "," : " - ") + moment().day(group[group.length - 1]).format("dddd")
        } else {
            return moment().day(group[0]).format("dddd")
        }
    }
    days.sort()
    /*return (days.length > 2 && isSequential(days)) ? moment().day(days[0]).format("dddd") + " - " + moment().day(days[days.length - 1]).format("dddd")
        :  (days.length == 2) ? moment().day(days[0]).format("dddd") + ", " + moment().day(days[days.length - 1]).format("dddd")
        : moment().day(days[0]).format("dddd")*/
    const groups = findSequentialGroups(days)
    return groups.map(value => formatSequentialGroups(value)).join(", ")
}

function findSequentialGroups(days: number[]) {
    const groups: number[][] = []
    let currentGroup: number[]|undefined = undefined

    for (let i = 0; i < days.length; i++) {
        const day = days[i]
        if (!currentGroup) currentGroup = [day]
        else {
            if (currentGroup[currentGroup.length - 1] + 1 == day) currentGroup.push(day)
            else {
                groups.push(currentGroup)
                currentGroup = [day]
            }
        }
    }
    if (currentGroup) groups.push(currentGroup)
    return groups
}


// Function to group consecutive days by working hours
export function groupConsecutiveDaysByWorkingHours(hoursMap: WorkingHoursMap): Schedule[] {
    const result: Schedule[] = [];
    let currentGroup: number[] = [];
    let currentHours: number[] = [];

    // Iterate over the days in order
    for (let day = 1; day <= 7; day++) {
        const hours = hoursMap[day] ?? []

        if (hours.length == 0) continue
        // If this is the first day or the hours are the same as the previous day's hours, add to current group
        if (currentHours?.length == 0 || areHoursEqual(currentHours, hours)) {
            currentGroup.push(day);
        } else {
            // If hours differ, store the previous group and start a new one
            if (currentHours.length != 0) result.push({ days: currentGroup, hours: currentHours });
            currentGroup = [day];
        }

        // Update current hours to the latest day's hours
        currentHours = hours;
    }

    // Add the last group
    if (currentGroup.length > 0 && currentHours.length > 0) {
        result.push({ days: currentGroup, hours: currentHours });
    }

    return result;
}