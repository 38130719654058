import {getAuth, User} from "firebase/auth";
import {app, db} from "../../firebase/config";
import {useEffect, useState} from "react";
import {doc, getDoc} from "@firebase/firestore";

export interface UserData {
    moderator: boolean,
    admin: boolean,
    uid: string,
    name: string,
    photoUrl: string
}

const auth = getAuth(app);

export function useIsModerator(): boolean {
    const user = useUser()
    return user != null && user.moderator
}

export function useUser(): UserData|null|undefined {

    const [userAuth, setUserAuth] = useState<User|undefined>()

    const [user, setUser] = useState<UserData|undefined|null>()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user == null) setUser(null)
            else {
                setUserAuth(user)
            }
        })
    },[]);

    useEffect(() => {
        const loadData = async () => {
            if (userAuth != null){
                const userEntry = (await getDoc(doc(db, "users", userAuth.uid))).data() as UserData
                setUser({
                    uid: userAuth.uid,
                    name: userAuth.displayName ?? "",
                    admin: userEntry.admin,
                    moderator: userEntry.admin,
                    photoUrl: userAuth.photoURL ?? ""
                })
            }
        }
        loadData()
    }, [userAuth]);

    return user
}