import {
    BarDeal,
    ChainDeal,
    IDeal,
    INewDeal,
    NewBarDealSuggestion,
    NewChainDealSuggestion,
    WithOriginalId
} from "./IDeal";
import {doc, DocumentData, DocumentReference, DocumentSnapshot} from "@firebase/firestore";
import {DealParent} from "./IMomentDeal";
import {getChain} from "../components/tools/getBarWithDeals";
import {db} from "../firebase/config";
import PlaceResult = google.maps.places.PlaceResult;

export interface IBar extends INewBar, WithId {}

export interface INewBar extends DealParent {
    name: string,
    address: string
}

export interface WithId {
    id: string
}

export interface NewBar extends INewBar {
    chain: BarChain|undefined
}

export interface Bar extends NewBar, WithId {}


export interface BarDetails {
    website: string,
    phoneNumber: string,
    servesBeer: boolean,
    servesCocktails: boolean,
    servesWine: boolean,
    servesCoffee: boolean,
}

export interface RawNewBar extends INewBar {
    chain: DocumentReference|null
}

export interface RawBar extends RawNewBar, WithId {}

export interface WithDeals<T extends INewDeal> extends DealParent {
    deals: T[]
}

export interface BarWithDeals extends Bar, WithDeals<BarDeal> {}

export interface BarSubmission extends Bar {
    maps_id: string,
    chain: ChainSubmission|undefined
}

export interface NewBarSubmission extends Omit<BarSubmission, "id"> {}

export function BarSubmission(snapshot: DocumentSnapshot<DocumentData, DocumentData>): BarSubmission {
    return {
        ...snapshot.data(),
        id: snapshot.id
    } as BarSubmission
}


export function sortedDealsNullable(bar: WithDeals<any> | null): IDeal[] | null {
    if (bar == null) return null
    else return withSortedDeals(bar)
}

export function withSortedDeals<T extends INewDeal>(bar: WithDeals<any>): T[] {
    return sortedDeals(bar.deals)
}

export function sortedDeals<T extends WithId|WithOriginalId>(deals: T[]): T[] {
    return deals.sort((a, b) => getSomeId(b).localeCompare(getSomeId(a)))
}

function hasOriginalId(obj: any): obj is WithOriginalId {
    return "originalId" in obj
}

function hasId(obj: any): obj is WithId {
    return "id" in obj
}

function getSomeId(obj: WithId|WithOriginalId): string {
    return hasOriginalId(obj) ? obj.original?.id ?? "" : (hasId(obj) ? obj.id : "")
}

export function sortById<T extends WithOriginalId|WithId>(deals: (T)[]): (T)[] {
    return deals.sort((a, b) => getSomeId(a).localeCompare(getSomeId(b)))
}

export function BarWithDeals(bar: Bar, deals: BarDeal[]): BarWithDeals {
    return {
        id: bar.id,
        address: bar.address,
        name: bar.name,
        chain: bar.chain,
        deals: deals
    }
}

export function MakeBarDealSuggestion(barDeal: BarDeal): NewBarDealSuggestion {
    return {
        barId: barDeal.barId,
        conditions: barDeal.conditions,
        description: barDeal.description,
        days: barDeal.days,
        original: doc(db, "deals", barDeal.id)
    }
}

export function MakeChainDealSuggestion(chainDeal: ChainDeal): NewChainDealSuggestion {
    return {
        chain: chainDeal.chain,
        conditions: chainDeal.conditions,
        description: chainDeal.description,
        days: chainDeal.days,
        original: doc(db, "deals", chainDeal.id)
    }
}

export function ExistingBarSubmission(bar: Bar): NewBarSubmission {
    return {
        address: bar.address,
        name: bar.name,
        maps_id: bar.id,
        chain: bar.chain
    } as NewBarSubmission
}

export function NewBarSubmission(placeResult: PlaceResult): NewBarSubmission {
    return {
        address: placeResult.formatted_address ?? "",
        name: placeResult.name ?? "",
        maps_id: placeResult.place_id ?? "",
        chain: undefined
    }
}

export function Bar(barSubmission: NewBarSubmission): Bar {
    return {
        address: barSubmission.address,
        name: barSubmission.name,
        id: barSubmission.maps_id,
        chain: barSubmission.chain
    }
}

export interface RawNewBarSubmission extends Omit<RawBar, "id"> {
    maps_id: string
}


export function RawBar(snapshot: DocumentSnapshot): RawBar|undefined {
    if (snapshot.exists()) {
        return {
            ...snapshot.data(),
            id: snapshot.id
        } as RawBar
    } else return undefined
}

export async function BarFromDB(snapshot: DocumentSnapshot, chain?: BarChain): Promise<Bar | undefined> {
    const raw = RawBar(snapshot)
    return raw ? {
        chain: chain ?? (raw.chain?.id ? await getChain(raw.chain.id) : undefined),
        id: raw.id,
        address: raw.address,
        name: raw.name
    } : undefined
}


export interface BarChain extends DealParent {
    id: string,
    name: string,
    size: number
}


export interface ChainSubmission extends BarChain, WithOriginalId {}

export function ChainSubmission(originalId: string, chain: BarChain): ChainSubmission {
    return {
        name: chain.name,
        original: doc(db, "chains", originalId),
    } as ChainSubmission
}


export function ChainSubmissionFromDB(data: DocumentSnapshot): ChainSubmission {
    return {
        id: data.id,
        ...data.data()
    } as ChainSubmission
}

export interface ChainWithDeals extends BarChain, WithDeals<ChainDeal> {}