import LanguagePicker from "./LanguagePicker";
import {Box, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Show, Text} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import React from "react";
import {ColorModePicker} from "./ColorModePicker";
import {useTranslations} from "next-intl";
import {Link, usePathname, useRouter} from "../i18n/routing";
import {UserData, useUser} from "./tools/useUser";
import {useDisclosure} from "@chakra-ui/react-use-disclosure";
import dynamic from "next/dynamic";

export function TopMenu() {

    const t = useTranslations("Home")
    const router = useRouter()
    const path = usePathname()
    const addbarVisible = !path.startsWith("/addbar")
    const aboutUsVisible = !path.startsWith("/about")

    const user = useUser()

    const {isOpen, onOpen, onClose} = useDisclosure()

    function UserView({user, onlyPicture=true}: {user: UserData, onlyPicture?: boolean}) {
        return (
            <Flex justifyContent="space-between" align="center" w="100%" onClick={onOpen}>
                {!onlyPicture && <Text height="max-content" m={0}>{user.name}</Text>}
                <Image borderRadius="50%" src={user.photoUrl} h="32px" minW="32px" alt="profile picture"></Image>
            </Flex>
        )
    }

    const DynamicUserDialog = dynamic(async () =>
        (await import('./ui/UserDialog')).UserDialog
    )

    return (
        <Box>
            {user != null && <DynamicUserDialog user={user} isOpen={isOpen} onClose={onClose}/>}
            <Show above="md">
                <HStack spacing={{base: "5px", md: "30px"}} >
                    {aboutUsVisible && (<Link href={{pathname: "/about"}}><Text color="white" m={0} minW="max-content">{t("about")}</Text></Link>)}
                    {addbarVisible && (<Link href={{pathname: "/addbar"}} target="_blank"><Text color="white" m={0} minW="max-content">{t("suggestEdit")}</Text></Link>)}
                    {user?.moderator == true && <Link href={{pathname: "/edit"}}><Text color="white">Admin panel</Text></Link>}
                    {user != null && (<UserView user={user}/>)}
                    <LanguagePicker withText={false}></LanguagePicker>
                    <ColorModePicker withText={false}></ColorModePicker>
                </HStack>
            </Show>
            <Show below="md">
                <Menu>
                    <MenuButton as={IconButton} icon={<HamburgerIcon/>}/>
                    <MenuList>
                        {user != null && (<MenuItem><UserView onlyPicture={false} user={user}/></MenuItem>)}
                        {user?.moderator == true && <MenuItem onClick={() => router.push("/edit")}>Admin panel</MenuItem>}
                        <MenuItem><LanguagePicker/></MenuItem>
                        <MenuItem><ColorModePicker/></MenuItem>
                        {addbarVisible && (<MenuItem onClick={() => router.push("/addbar")}>
                            {t("suggestEdit")}
                        </MenuItem>)}
                        {aboutUsVisible && (<MenuItem onClick={() => router.push("/about")}>{t("about")}</MenuItem>)}
                    </MenuList>
                </Menu>
            </Show>
        </Box>
    )
}